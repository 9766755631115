// STYLES IMPORT
// CONTEXTS IMPORTS
// COMPONENTS IMPORTS
import React, { useEffect } from "react";
import ReactGA from 'react-ga4';
import {  Link, useLocation } from 'react-router-dom'

ReactGA.initialize(process.env.REACT_APP_TRACKING_GA_KEY);

const NotFound = ()=> {

    const location = useLocation();

    useEffect(
        ()=> {
            window.scrollTo(0, 0);
            ReactGA.send({ hitType: "pageview", title:"Page not Found", page: "/notFound", searchedPage: location.pathname });
        }, [location.pathname]
    )

    return (
            <div className="content-wrapper">
                <h1>Page Not found</h1>
                <p>There has been an error, please try again later</p>
                <div className="button-link">
                    <Link to="/" onClick={()=> ReactGA.send({ hitType: "navigation", startingPage:"404 page", targetPage: "Homepage" })}>Home</Link>
                </div>
            </div>
    )
}

export default NotFound
