import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

api.defaults.headers.common['client'] = process.env.REACT_APP_CLIENT
api.defaults.headers.common['promotion'] = process.env.REACT_APP_PROJECT
api.defaults.headers.common['x-api-key'] = process.env.REACT_APP_API_KEY

export default api
