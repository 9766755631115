import { Link } from 'react-router-dom'
import logoFooter from '../assets/images/logo-footer.png'

const Footer = ()=> {
    return (
        <footer>
            <div>
                <Link to="/terms">Terms & Conditions</Link>
                <a className='footer-logo' href="https://robinsonssquash.co.uk/"><img src={logoFooter} alt="logo" /></a>
                <a href="https://www.britvic.com/site-services/privacy">Privacy policy</a>
            </div>
    </footer>
    )
}

export default Footer