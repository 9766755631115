import { useContext, useEffect } from "react"
import { AppContext } from "../contexts/AppContext"
import ReactGA from 'react-ga4';
import { Link } from "react-router-dom";

ReactGA.initialize(process.env.REACT_APP_TRACKING_GA_KEY);

const SuccessPage = ()=> {

    const { successCopy } = useContext(AppContext);

    useEffect(
        ()=> {
            window.scrollTo(0, 0);
            ReactGA.send({ hitType: "pageview", title:"Success Page", page: "/success" });
        }, []
    )


    return (
        <div className="content-wrapper">
            <h1>{successCopy.title}</h1>
            <div dangerouslySetInnerHTML={{__html: successCopy.message}}></div>
            <div className="button-link">
                <Link to="/" onClick={()=> ReactGA.send({ hitType: "navigation", startingPage:"Success Page", targetPage: "Homepage" })}>Home</Link>
            </div>
        </div>
    )
}

export default SuccessPage