import { useContext, useEffect } from "react"
import { Link } from "react-router-dom"
import { AppContext } from "../contexts/AppContext"
import ReactGA from 'react-ga4';

ReactGA.initialize(process.env.REACT_APP_TRACKING_GA_KEY);

const CompClosed = ()=> {

    const { dataState, checkDate } = useContext(AppContext)

    useEffect(
        ()=> {
            window.scrollTo(0, 0);
        }, []
    )

    const pageContent = ()=> {
        if (checkDate().reason) { 
            return (
                <>
                    <h1>{dataState.content.closed[checkDate().reason].title}</h1>
                    <div dangerouslySetInnerHTML={{__html: dataState.content.closed[checkDate().reason].content}}></div>
                </>
            )
        } else {
            return (
                <>
                    <h1>The Competition is Now Closed</h1>
                    <p>The competition is not live yet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec condimentum nunc malesuada neque placerat, et consectetur elit dapibus.</p>
                </>
            )
        }
    }


    return (
        <div className="content-wrapper">
           {pageContent()}
           <div className="button-link">
            <Link to="/" onClick={()=> ReactGA.send({ hitType: "navigation", startingPage:"Competition is closed", targetPage: "Homepage" })}>Home</Link>
           </div>
        </div>
    )
}

export default CompClosed