import React, { createContext, useState } from 'react';

export const AppContext = createContext();

const AppContextProvider = ({children})=> {
    
    const deviceSize = ()=> {
        if (window.screen.width>=1080) return "large"
        else if (window.screen.width<1080 && window.screen.width>680) return "medium"
        else if (window.screen.width<=680) return "small"
    };



    const [dataState, setDataState] = useState({
        "name": "Hundred",
        "description": null,
        "start_date": "2022-06-13 00:00:00",
        "end_date": "2022-07-31 23:59:59",
        "content": {
            "home": {
                "home": {
                    "title": "",
                    "content": ""
                }
            },
            "closed": {
                "before": {
                    "title": "",
                    "content": ""
                },
                "after": {
                    "title": "",
                    "content": ""
                }
            },
            "terms": {
                "terms": {
                    "title": "",
                    "content": ""
                }
            }
        }
    });

    const [successCopy, setSuccessCopy] = useState(
        {
            message: "",
            title: ""
        }
    )

    const [errorCopy, setErrorCopy] = useState(
        {
            message: "",
            title: ""
        }
    )
  
    const checkDate = () => {
        const today = new Date();
        const startDate = new Date(dataState.start_date.replace(/-/g, '/'));
        const endDate = new Date(dataState.end_date.replace(/-/g, '/'));
        if (today.getTime() < startDate.getTime() || today.getTime() > endDate.getTime()) {
            return {
                value: false,
                reason: today.getTime() < startDate.getTime() ? "before" : "after"
            };
        } else {
            return {
                value: true
            };
        }
    };

    // const checkDateMove = () => {
    //     const today = new Date();
    //     const startDate = new Date(dataState.start_date);
    //     const endDate = new Date(dataState.end_date);
        
    //     if (today.getTime() < startDate.getTime() || today.getTime() > endDate.getTime()) {
           
    //         window.scrollTo(0, 0);
    //         Navigate("compclosed")
    //     }
    // }


    return(
        <AppContext.Provider value={{
            dataState,
            setDataState,
            deviceSize,
            checkDate,
            successCopy,
            setSuccessCopy,
            errorCopy,
            setErrorCopy
            }}>
            {children}
        </AppContext.Provider>
    )
}

export default AppContextProvider;