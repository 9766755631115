// CONTEXTS IMPORTS
// COMPONENTS IMPORTS
import React, { useEffect, useContext } from "react";
import { AppContext } from "../contexts/AppContext";
import ReactGA from 'react-ga4';
import { Link } from "react-router-dom";

ReactGA.initialize(process.env.REACT_APP_TRACKING_GA_KEY);


const Error = ()=> {

    const { errorCopy } = useContext(AppContext);

    useEffect(
        ()=> {
            ReactGA.send({ hitType: "pageview",title: "Error Page", page: "/error" });
        }, []
    )

    useEffect(
        ()=> {
            window.scrollTo(0, 0);
        }, []
    )

    
    return (
        <div className="content-wrapper">
            <h1>{errorCopy.title !== "" ? errorCopy.title : "Whoops, something went wrong"}</h1>
            <div dangerouslySetInnerHTML={{__html: errorCopy.message !== "" ? `<p>${errorCopy.message}</p>` : "<p>Please try again later<p>"}}></div>
            <div className="button-link">
                <Link to="/" onClick={()=> ReactGA.send({ hitType: "navigation", startingPage:"Error page", targetPage: "Homepage" })}>Home</Link>
            </div>
        </div>
    )
}

export default Error
