// CONTEXTS IMPORTS
import { AppContext } from "./contexts/AppContext";
// COMPONENTS IMPORTS
import React, { useContext } from "react";
import { useQuery } from "react-query";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import api from "./api";
import LogoBox from "./components/Logobox";
import Loading from "./pages/Loading";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Error from "./pages/Error";
import SuccessPage from "./pages/SuccessPage";
import Terms from "./pages/Terms";
import CompClosed from "./pages/CompClosed";
import NotFound from "./pages/NotFound";
//  STYLES IMPORTS
import "./styles/global.scss";
import AthletesWrapper from './components/AthletesWrapper';
import LoadingError from "./components/LoadingError";

function App() {
  const { deviceSize, setDataState } = useContext(AppContext);

  const { isLoading, error } = useQuery(
    "getContent",
    () =>
      api.get("/content").then((result) => {
        setDataState(result.data);
      }),
    { keepPreviousData: true }
  );

  return (
    <div className="App" style={{backgroundImage: `url(./images/${deviceSize()}/sky.png)`}}>
        {(isLoading) ? <Loading /> : (
            <BrowserRouter>
              {error ? <LoadingError /> : (
              <AthletesWrapper>
                <LogoBox />
                  <Routes>
                      <Route path="/" element={<Home />}></Route>
                      <Route path="error" element={<Error />}></Route>
                      <Route path="success" element={<SuccessPage />}></Route>
                      <Route path="terms" element={<Terms />}></Route>
                      <Route path="compclosed" element={<CompClosed />}></Route>
                      <Route path="*" element={<NotFound/>}></Route>
                  </Routes>
              </AthletesWrapper>
              )}
                <Footer/>
            </BrowserRouter>
        )}
    </div>
  );
}

export default App;
