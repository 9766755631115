import { useContext, useState } from "react";
import api from '../api';
import { useNavigate } from "react-router-dom";
import { AppContext } from "../contexts/AppContext";

const FormWrapper = () => {

  const { setSuccessCopy, setErrorCopy } = useContext(AppContext)

      // STATE
      const [ nameState, setNameState ] = useState("");
      const [ emailState, setEmailState ] = useState("");
      const [ phoneState, setPhoneState ] = useState("");
      const [ whereState, setWhereState ] = useState("default");
      const [ over18State, setOver18State ] = useState(false);
      const [ termsState, setTermsState ] = useState(false);
      const [ promotionsState, setPromotionsState ] = useState(false);
      const [ detailsErrorState, setDetailsErrorState ] =useState(false);
  
      const [ wrongName, setWrongName ] = useState(false)
      const [ wrongEmail, setWrongEmail ] = useState(false)
      const [ wrongTerms, setWrongTerms ] = useState(false)
      const [ wrongPhone, setWrongPhone ] = useState(false)
      const [ wrongWhere, setWrongWhere ] = useState(false)
      const [ not18, setNot18 ] = useState(false)

      let navigate = useNavigate();
      
      // VALIDATION
      let pushToNewPage;
  
      const fieldsData = [
          {
              trigger: setWrongName,
              condition: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u.test(nameState) && nameState.length > 2
          },
          {
              trigger: setWrongEmail,
              condition: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(emailState)
          },
          {
              trigger: setWrongPhone,
              condition: /^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|#)\d{3,4})?$/.test(phoneState)
          },
          {
              trigger: setWrongWhere,
              condition: whereState !== "default"
          },
          {
              trigger: setNot18,
              condition: over18State === true
          },
          {
              trigger: setWrongTerms,
              condition: termsState === true
          },
      ]
  
      const ValidateFields = ()=> {
          fieldsData.forEach(
              single => {
                  if (single.condition) {
                          single.trigger(false)
                  } else {
                      pushToNewPage = false;
                          single.trigger(true)
                      setDetailsErrorState(true)
                  }
              }
          )
      }

      const ValidateSingle = (object)=> {
          
                  if (object.condition) {
                    object.trigger(false)
                  } else {
                      pushToNewPage = false;
                      object.trigger(true)
                      setDetailsErrorState(true)
                  }
              }

  
      // // Function that gets called on "send" button activation
      const sendInfo = async ()=> {
          pushToNewPage = true;
          ValidateFields()
              if (pushToNewPage === true) {
                  const answersObject = {
                      email: emailState.toLowerCase(),
                      name: nameState,
                      phone: phoneState,
                      where: whereState,
                      marketing: promotionsState,
                      age: over18State,
                      terms: termsState,
                  };
                  try{
                    await api.post('/enter', answersObject)
                  .then(result => {
                      window.scrollTo(0, 0);
                      if(result.data.result === 'Success'){
                        setSuccessCopy({message: result.data.message, title: result.data.title})
                        navigate("/success")
                      } else {
                        navigate("/error")
                      }
                    })
                  }
                  catch (error) {
                    if (error.response.data.errors) setErrorCopy({message: error.response.data.errors.message, title: error.response.data.errors.title})
                      navigate("/error")
                  }
              }
      }

      const colorVariables = {
        green: '#1b7125',
        error: '#ed1414',
      }
  




  return (
    <form className="form-wrapper" action="submit">
      <h3>Tell us about yourself</h3>
      {/* NAME INPUT */}
      <div className="form-wrapper__input">
        <div className="form-wrapper__box">
          <label style={{color: wrongName ? colorVariables.error : colorVariables.green}} htmlFor="name">Name*:</label>
          <div className="error-text" style={{opacity: wrongName ? 1 : 0}}>
            <i>!</i>
            <p>You must provide a valid name</p>
          </div>
        </div>
        <input className={ wrongName ? "input-error" : "input-correct"} type="text" name="name" id="name" placeholder="" onChange={ e => {setNameState(e.target.value)}} onBlur={()=> {ValidateSingle(fieldsData[0])}} />
      </div>
      {/* EMAIL INPUT */}
      <div className="form-wrapper__input">
        <div className="form-wrapper__box">
          <label style={{color: wrongEmail ? colorVariables.error : colorVariables.green}} htmlFor="email">Email*:</label>
          <div className="error-text" style={{opacity: wrongEmail ? 1 : 0}}>
            <i>!</i>
            <p>You must provide a valid email</p>
          </div>
        </div>
        <input className={ wrongEmail ? "input-error" : "input-correct"} type="email" name="email" id="email" placeholder="" onChange={ e => {setEmailState(e.target.value)}}  onBlur={()=> {ValidateSingle(fieldsData[1])}}/>
      </div>
      {/* PHONE INPUT */}
      <div className="form-wrapper__input">
        <div className="form-wrapper__box">
          <label style={{color: wrongPhone ? colorVariables.error : colorVariables.green}} htmlFor="phone">Telephone Number*:</label>
          <div className="error-text" style={{opacity: wrongPhone ? 1 : 0}}>
            <i>!</i>
            <p>You must provide a valid phone number</p>
          </div>
        </div>
        <input className={ wrongPhone ? "input-error" : "input-correct"} type="tel" name="phone" id="phone" placeholder="" onChange={ e => {setPhoneState(e.target.value)}} onBlur={()=> {ValidateSingle(fieldsData[2])}}/>
      </div>
      {/* STORE INPUT */}
      <div className="form-wrapper__select">
        <div className="form-wrapper__box">
          <label style={{color: wrongWhere ? colorVariables.error : colorVariables.green}} htmlFor="stores">Which retailer did you purchase our Ready To Drink juice from?*:</label>
          <div className="error-text" style={{opacity: wrongWhere ? 1 : 0}}>
            <i>!</i>
            <p>You must provide a valid location</p>
          </div>
        </div>
        <select  className={ wrongWhere ? "input-error" : "input-correct"} name="stores" id="stores" value={whereState} onChange={e => setWhereState(e.target.value)} onBlur={()=> {ValidateSingle(fieldsData[3])}}>
          <option value="default">select</option>
          <option value="Asda">Asda</option>
          <option value="Morrisons">Morrisons</option>
          <option value="Tesco">Tesco</option>
          <option value="Iceland">Iceland</option>
          <option value="Sainsburys">Sainsburys</option>
          <option value="Co-op">Co-op</option>
          <option value="Waitrose">Waitrose</option>
          <option value="Ocado">Ocado</option>
          <option value="other">Other</option>
      </select>
      </div>

      <div className="form-wrapper__checkboxes">
        {/* AGE CHECKBOX */}
        <div className="form-wrapper__checkbox">
          <div className="check-and-label">
            <input
              type="checkbox"
              id="age"
              name="age"
              onChange={ () => {setOver18State(!over18State)}}
              className={ not18 ? "input-error" : "input-correct"}
            />
            <label style={{color: not18 ? colorVariables.error : colorVariables.green}} htmlFor="age">I am over the age of 18 *</label>
          </div>
        </div>
        {/* T&C CHECKBOX */}
        <div className="form-wrapper__checkbox">
          <div className="check-and-label">
            <input
              type="checkbox"
              id="terms"
              name="terms"
              onChange={ () => {setTermsState(!termsState)}}
            />
            <label style={{color: wrongTerms ? colorVariables.error : colorVariables.green}} htmlFor="terms">I accept the terms & conditions, privacy policy and cookie policy*</label>
          </div>
        </div>
        {/* MARKETING CHECKBOX */}
        <div className="form-wrapper__checkbox">
          <div className="check-and-label">
            <input
              type="checkbox"
              id="marketing"
              name="marketing"
              onChange={ () => {setPromotionsState(!promotionsState)}}
            />
            <label htmlFor="marketing">I would like to receive marketing material.</label>
          </div>
        </div>
        <p className="light">*These fields are mandatory.</p>
      </div>
      <p style={{opacity: detailsErrorState ? 1 : 0, color: colorVariables.error}}>One or more field have an invalid value</p>
            {/* "Send" button */}
      <button onClick={e => {e.preventDefault(); sendInfo()}}>submit</button>
    </form>
  );
};

export default FormWrapper;
