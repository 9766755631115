const Loading = ()=> {
    return (
        <div id="loading-wrapper"  style={{backgroundImage: `url(./images/small/sky.png)`}}>
            <div>
                <img src="./images/CricketBall.png" alt="Loading"></img>
                <p>Loading...</p>
            </div>
        </div>
    )
}

export default Loading