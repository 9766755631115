import { useContext, useEffect } from "react"
import { AppContext } from "../contexts/AppContext"
import FormWrapper from "../components/Form"
import ReactGA from 'react-ga4';
import { useNavigate } from "react-router-dom";

ReactGA.initialize(process.env.REACT_APP_TRACKING_GA_KEY);

const Home = ()=> {

const { dataState, checkDate } = useContext(AppContext);
const navigate = useNavigate();

useEffect(
    ()=> {
        window.scrollTo(0, 0);
        ReactGA.send({ hitType: "pageview", title:"Homepage", page: "/" });
    }, []
)

useEffect(
    ()=> {
        if (checkDate().value === false) {
            navigate("compclosed")
        }
    }
)

    return (
        <>
            <div className="content-wrapper">
                <h1>{dataState.content.home.home.title}</h1>
                <div dangerouslySetInnerHTML={{__html: dataState.content.home.home.content}}></div>
            </div>
            <div className="content-wrapper">
                <h2>Step 1</h2>
                <FormWrapper />
            </div>
        </>
    )
}

export default Home