import Logo from "../assets/images/logo.png";
import { Link, useLocation } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../contexts/AppContext";
import ReactGA from 'react-ga4';

const LogoBox = () => {

  const { deviceSize } = useContext(AppContext);
  const location = useLocation();


    return (
      <div className="logobox" id={location.pathname === "/terms" || deviceSize() !=="large" ? "logobox-centered" : ""} >
        <Link to="/" onClick={()=> ReactGA.send({ hitType: "navigation", startingPage: location.pathname, targetPage: "Homepage" })}>
          <img className="logo" src={Logo} alt="logo" />
        </Link>
      </div>
      ) 
}

export default LogoBox