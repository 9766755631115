import { useContext, useEffect } from "react"
import { AppContext } from "../contexts/AppContext"
import ReactGA from 'react-ga4';
import { Link } from "react-router-dom";

ReactGA.initialize(process.env.REACT_APP_TRACKING_GA_KEY);

const Terms = ()=> {
    const { dataState } = useContext(AppContext)

    useEffect(
        ()=> {
            window.scrollTo(0, 0);
            ReactGA.send({ hitType: "pageview", title:"Terms page", page: "/terms" });
        }, []
    )


    return (
        <div className="content-wrapper">
            <h1>{dataState.content.terms.terms.title}</h1>
            <div dangerouslySetInnerHTML={{__html: dataState.content.terms.terms.content}}></div>
            <div className="button-link">
                <Link to="/" onClick={()=> ReactGA.send({ hitType: "navigation", startingPage:"Terms page", targetPage: "Homepage" })}>Home</Link>
            </div>
        </div>
    )
}

export default Terms