import React, { useContext } from "react"
import { AppContext } from "../contexts/AppContext"
import {  useLocation } from 'react-router-dom'


const AthletesWrapper = ({children})=> {

    const { deviceSize } = useContext(AppContext);
    const location = useLocation();


    return (
        <div className="athletes-wrapper" id={location.pathname === "/terms" || deviceSize() !=="large" ? "athletes-terms" : ""} style={{backgroundImage: location.pathname !== "/terms" && deviceSize() ==="large" ? `url(./images/${deviceSize()}/athletes.png)` : ""}}>
            {children}
            {(location.pathname === "/terms" || deviceSize() !=="large") && <img id="athletes-bottom-image" src={`./images/${deviceSize()}/athletes.png`} alt=""></img>}
        </div>
    )
}

export default AthletesWrapper